import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import ReactPlayer from "react-player";
import { device } from "../utils";
import trust from "../assets/image/png/trust.svg"; // Adjust the import according to your project structure

import Newarrow from '../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;


const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Iline = styled.div`
  width: 6em;
  height: 1px;
  margin-right: 1em;
  background-color: rgb(171, 142, 102);

  @media screen and (max-width: 991px) {
    width: 3em;
  }
`;

const Itext = styled.h1`
  margin-top: 0px;
  margin-bottom: 12px;
  
  font-size: 14px;
text-transform:uppercase;


  font-weight: 500;

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h2`
  transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  font-size: 2.2em;

  font-smooth: always;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const IconButton = styled(Box)`
  cursor: pointer;
  font-size: 17px;
  background-color: transparent;
  border: 1px solid rgb(171, 142, 102);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: rgb(171, 142, 102);
  width: 50px;
  height: 50px;
  opacity: 0.9; // Adjust the opacity value as needed
  @media ${device.sm} {
    width: 50px;
    height: 50px;
  }
  @media ${device.md} {
    width: 50px;
    height: 50px;
  }
`;


const ModalStyled = styled(Modal)`
  .modal-content {
    background:none;
    border: none;
    border-radius: 0;
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

const ReactPlayerStyled = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  top: 0;
  right: 1rem;
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  z-index: 10;
  color: #fff;
  @media ${device.md} {
    right: 2rem;
  }
`;

const HtmlContent = ({ html }) => (
  <Itext dangerouslySetInnerHTML={{ __html: html }} />
);

const CloseButton = (props) => (
  <CloseWrapper {...props}>
    <svg
      role="img"
      viewBox="0 0 24 24"
      css={`
        fill: currentcolor;
        vertical-align: middle;
        height: 1rem;
        width: 1rem;
      `}
    >
      <path
        d="M9.82 12L0 2.18 2.18 0 12 9.82 21.82 0 24 2.18 14.18 12 24 21.82 21.82 24 12 14.18 2.18 24 0 21.82z"
        fill="currentColor"
      ></path>
    </svg>
  </CloseWrapper>
);

const HeroComponent = ({ itext, secondText, videoAlt, imgVideo }) => {


  return (
    <>
      <Section py={4} className="position-relative mt-lg-5 mb-lg- pt-lg-5 pb-lg-5">
        <div className="pt-5 mt-3 pb-0 mb-0"></div>
        <Container>
          <Row className="justify-content-center align-items-center">
          <Col lg="6" className="order-lg-1">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box className="pr-lg-5">
                  <Iwrap>
                   
                  <HtmlContent html={itext} />
                  </Iwrap>
                  <SecondText>{secondText}</SecondText> 
                 <div className="d-none d-sm-block"> <a data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="500" className="pb-2" href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>Book appointment</Bookbutton> </a>
                  </div><br />
                  <a className="d-flex align-items-center justify-content-center d-block d-sm-none " href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} loading="lazy" width="67%" /></a>

<a className=" d-none d-sm-block 	d-sm-none d-md-block" href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank"> <img src={trust} width="50%" /></a> 
 <br />
                </Box>
              </div>
            </Col>
            <Col lg="6" md="8" sm="9" className="order-lg-2">
              <div className="text-center text-lg-right position-relative">
                <Box
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="false"
                  data-aos-delay="300"
                >
                  <img
                    src={imgVideo}
                    alt={videoAlt}
                    loading="lazy"
                    className="rounded shadow"
                  />
                 
                </Box> <br />
                <a data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="500" className="d-flex align-items-center justify-content-center d-block d-sm-none pt-2" href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>Book appointment</Bookbutton> </a>
              </div>
            </Col>
           
          </Row>
        </Container>
      </Section>

      
    </>
  );
};

export default HeroComponent;
